import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";
import MockAccessRules from "./MockAccessRules.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination, MockAccessRules],
  data() {
    return {
      searchTimeOut: null,
      filtersData: {},
    }
  },
  computed: {
    srvr() {
      return {
        name: this.currentServer ? this.currentServer.host : 'No name',
        status: this.currentServer ? this.currentServer.status : false,
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
          {
            id: "srvrerr",
            type: "failed",
            icon: "cross",
            label: "Сервер не работает",
          },
        ],
        count: 3,
      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "address",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Адреса",
            options: this.propertyListOptions,
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адресс",
          onclick: () => {
            this.filtersData.order = 'address';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredUnits();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredUnits();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name'
        },
        {
          id: "flat_type",
          label: "Тип помещения",
          onclick: () => {
            this.filtersData.order = 'unit_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredUnits();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_type',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "account_number",
          label: "Лицевой счет",
          onclick: () => {
            this.filtersData.order = 'account_number';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredUnits();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'account_number'
        },
        // {
        //   id: "section",
        //   label: "Подъезд",
        //   onclick: () => {
        //     this.filtersData.order = 'section';
        //     this.toggleColumnOrderType(this.filtersData.order);
        //     this.getFilteredUnits();
        //   },
        //   clickable: true,
        //   orderType: this.filtersData.order_type,
        //   selected: this.filtersData.order === 'section'
        // },
      ];
    },
    tableRows() {
      const data = [];

      this.unitList.forEach((unit) => {
        data.push({
          id: unit.id,
          columns: [
            {
              id: "address",
              content: {
                id: unit.id,
                label: unit.meter_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: unit.id,
                label: unit.name,
              },
            },
            {
              id: "flat_type",
              content: {
                id: unit.id,
                label: this.unitTypes[unit.type] ?? '-',
              },
            },
            {
              id: "account_number",
              content: {
                id: unit.id,
                label: unit.account_number,
              },
            },
            // {
            //   id: "section",
            //   content: {
            //     id: unit.id,
            //     label: unit.section ?? '-',
            //   },
            // },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 1,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Редактировать сервер",
              icon: "edit",
              handler: () => this.onEditServer(),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить cервер",
              icon: "trash",
              handler: () => this.onDeleteServer(this.$route.params.serverId),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          // {
          //   id: 3,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     label: "Изменить верификацию",
          //     icon: "check",
          //     handler: () => this.verifySelectedContacts(),
          //   },
          // },
          // {
          //   id: 4,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     colored: "danger",
          //     label: "Удалить строку",
          //     icon: "trash",
          //     handler: () => this.deleteContacts(),
          //   },
          // },
        ],
      };
    },
    currentServer() {
      return this.serverList.filter(server => server.id === this.$route.params.serverId)[0] ?? null
    },
    server() {
      return {
        host: this.currentServer.host,
        // port: this.currentServer.port,
        login: this.currentServer.login,
        password: this.currentServer.password,
      };
    },
  },
  mounted() {
    this.$store.dispatch('setIsLoading', true)

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId,
      });
    }

    this.getProperties({
      organizationId: this.currentOrganizationId,
      enviroServerId: this.$route.params.serverId
    });

    this.getUnits({
      organizationId: this.currentOrganizationId,
      enviroServerId: this.$route.params.serverId
    }).then(response => {
      this.setUnitsPagination();
      this.$store.dispatch('setIsLoading', false)
    }).catch(error => {
      this.$store.dispatch('setIsLoading', false)
    });
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    getFilteredUnits() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.enviroServerId = this.$route.params.serverId;

      this.getUnits(this.filtersData).then(response => {
        this.setUnitsPagination();
      });
      
    },
    filterUnits(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.enviroServerId = this.$route.params.serverId;
        this.filtersData = filters;

        this.getFilteredUnits();
      }, 2000);
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row)
    },
    deleteContacts() {
      this.$store.dispatch('setIsLoading', true)
      this.unitService.removeContacts(this.rowsSelected).then(response => {
        console.log(response);
        this.getFilteredUnits();
        this.$store.dispatch('setIsLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false)
      })
    },
    setUnitsPagination() {
      this.initPagination(
        this.unitsMeta.current_page,
        this.unitsMeta.total,
        this.unitsMeta.per_page
      );
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredUnits();
    },
    onServerPopupApprove() {
      this.serverService.updateServer(this.$route.params.serverId, {
        ouid: this.currentOrganizationId,
        host: this.serverPopup.modelValue.host,
        login: this.serverPopup.modelValue.login,
        password: this.serverPopup.modelValue.password,
      }).then(response => {
        if (response.data) {
          this.getServers({
            organizationId: this.currentOrganizationId,
          });

          this.initPagination(
            this.serversMeta.current_page,
            this.serversMeta.total,
            this.serversMeta.per_page
          );
        }

        this.serverPopup.opened = false;
      }).catch((error) => {
        console.log(error)
        this.serverPopup.opened = false;
      });
    },
  }
};

import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      searchTimeOut: null,
      currentMeterConfig: null,
      filtersData: {}
    };
  },
  computed: {
    address() {
      return {
        address: this.currentAddress,
        ip: this.currentServer ? this.currentServer.host : null,
        status: this.currentServer ? this.currentServer.status : false,
        chips: [
          {
            id: "srvrwrks",
            type: "complited",
            icon: "check",
            label: "Сервер работает",
          },
          {
            id: "srvrerr",
            type: "failed",
            icon: "cross",
            label: "Сервер не работает",
          },
        ],
        count: this.unitList.length ?? 0,
      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "unit_type",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Тип квартиры",
            options: this.unitTypeListOptions,
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredUnits();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "flat_type",
          label: "Тип помещения",
          onclick: () => {
            this.filtersData.order = 'unit_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_type',
          style: {
            minWidth: '130px',
          }
        },
        // {
        //   id: "section",
        //   label: "Подъезд",
        //   onclick: () => {
        //     this.filtersData.order = 'section';
        //     this.toggleColumnOrderType(this.filtersData.order);
        //     this.getFilteredContacts();
        //   },
        //   clickable: true,
        //   orderType: this.filtersData.order_type,
        //   selected: this.filtersData.order === 'section',
        // },
        {
          id: "account_number",
          label: "Лицевой счет",
          onclick: () => {
            this.filtersData.order = 'account_number';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredUnits();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'account_number'
        },
      ];
    },
    tableRows() {
      const data = [];
      this.unitList.forEach((unit) => {
        data.push({
          id: unit.id,
          columns: [
            {
              id: "address",
              content: {
                id: unit.id,
                label: unit.meter_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: unit.id,
                label: unit.name,
              },
            },
            {
              id: "flat_type",
              content: {
                id: unit.id,
                label: this.unitTypes[unit.type] ?? '-',
              },
            },
            {
              id: "account_number",
              content: {
                id: unit.id,
                label: unit.account_number,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 1,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Редактировать сервер",
              icon: "edit",
              handler: () => this.onEditServer(),
            },
          },
          // {
          //   id: 2,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     label: "Добавить абонентов через файл",
          //     icon: "upload-file",
          //     handler: () => {
          //       this.filePopup.opened = true;
          //     },
          //   },
          // },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              colored: "danger",
              label: "Удалить телеметрию",
              icon: "trash",
              handler: () => this.deleteMeterConfig(this.$route.params.meterConfigId),
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          // {
          //   id: 3,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     label: "Изменить верификацию",
          //     icon: "check",
          //     handler: () => this.verifySelectedContacts(),
          //   },
          // },
          // {
          //   id: 4,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     colored: "danger",
          //     label: "Удалить строку",
          //     icon: "trash",
          //     handler: () => this.deleteContacts(),
          //   },
          // },
        ],
      };
    },
    currentAddress() {
      return this.currentMeterConfig && this.currentMeterConfig.property ? this.currentMeterConfig.property.address : null;
    },
    currentServer() {
      return this.currentMeterConfig && this.currentMeterConfig.server ? this.currentMeterConfig.server : null;
    },
    server() {
      return {
        id: this.currentServer.id,
        host: this.currentServer.host,
        // port: this.currentServer.port,
        login: this.currentServer.login,
        password: this.currentServer.password,
      };
    },
  },
  methods: {
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row)
    },
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    verifyContact(contactId) {
      this.$store.dispatch('setIsLoading', true);
      this.unitService.verifyContacts({contacts: [{id: contactId}]}).then(response => {
        this.getCurrentMeterConfigUnits();
        this.$store.dispatch('setIsLoading', false);
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    },
    deleteMeterConfig(meterConfigId) {
      this.meterConfigService.removeMeterConfigs([meterConfigId]).then(response => {
          this.getCurrentMeterConfigUnits();
          this.$router.push({name: 'MeterConfigs'})
      }).catch((error) => {
        console.log(error)
      })
    },
    deleteContacts() {
      this.unitService.removeContacts(this.rowsSelected).then(response => {
        this.getCurrentMeterConfigUnits();
      }).catch(error => {
        console.log(error)
      })
    },
    filterUnits(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.meterConfigId = this.$route.params.meterConfigId;
        this.filtersData = filters;

        this.getFilteredUnits();
      }, 2000);
    },
    getCurrentMeterConfigUnits() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.meterConfigId = this.$route.params.meterConfigId;

      this.getFilteredUnits();
    },
    setUnitsPagination() {
      this.initPagination(
        this.unitsMeta.current_page,
        this.unitsMeta.total,
        this.unitsMeta.per_page
      );
    },
    getFilteredUnits() {
      this.getUnits(this.filtersData).then(response => {
        this.setUnitsPagination();
      });
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredUnits();
    },
  },
  async mounted() {
    this.$store.dispatch('setIsLoading', true);
    await this.meterConfigService.getMeterConfig(this.$route.params.meterConfigId).then(response => {
      if (response.data) {
          this.currentMeterConfig = response.data
      }
    }).catch((error) => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
    })

    this.$store.dispatch('setIsLoading', false);
    

    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId
      });
    }

    if (this.propertyList.length === 0) {
      this.getProperties({
        organizationId: this.currentOrganizationId
      });
    }

    this.getCurrentMeterConfigUnits();
  },
};

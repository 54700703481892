import httpProvider from 'Providers/http.js';
import config from 'MainDir/config.js';

export default {
    getOauthLink(condoOrganizationId = null, condoUserId = null) {
        let uriParams = condoOrganizationId && condoUserId 
            ? `?condoOrganizationId=${condoOrganizationId}&condoUserId=${condoUserId}`
            : '';

        return new Promise((resolve, reject) => {
            httpProvider.post('/auth' + uriParams)
            .then((response) => {
                if (response.data) {
                    resolve(response.data)
                } else {
                    reject('No content');
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    getAccessTokenWithCodeAndState(code, state) {
        return new Promise((resolve, reject) => {
            httpProvider.post(`/oidc/callback?code=${code}&state=${state}`)
            .then((response) => {
                if (response.data) {
                    resolve(response.data)
                } else {
                    reject('No content');
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    authRedirect(condoUserId, condoOrganizationId) {
        window.location.href = `${config.server}?condoUserId=${condoUserId}&condoOrganizationId=${condoOrganizationId}`;
    }
}
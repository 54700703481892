<template>
  <section class="address-list">
    <Header class="address-list__title" type="h3">
      <template v-slot:title>Проверка данных</template>
    </Header>
    <div class="address-list__notification">
      <!-- <Notification
        >Чтобы житель мог пользоваться доступами, вам нужно его
        верифицировать</Notification
      > -->
    </div>
    <div class="address-list__filters">
      <Filters v-model="filtersActive" :filters="filters" />
    </div>
    <div class="address-list__list">
      <Table
        v-model="rowsSelected"
        :columns="tableColumns"
        :rows="tableRows"
        :selectable="false"
        @switcherClick="onSwitcherClick"
        @rowClick="onRowClick"
      />
      <Pagination v-model="currentPage" v-bind="pagination" @switchPage="onSwitchPage" />
    </div>
    <div class="address-list__actions">
      <Actions
        :values="actionValues"
        :actions="rowsSelected.length ? actions.selected : actions.default"
      />
    </div>
  </section>
</template>

<script>
import Popup from "../../components/Popup.vue";
import Select from "../../components/Form/Select.vue";
import Filters from "Components/Filters.vue";
import Table from "Components/Table.vue";
import Actions from "Components/Actions.vue";
import Notification from "Components/Notification.vue";
import MeterConfigUnitList from "Mixins/Mock/MeterConfigUnitList.js";
import Header from "../../components/Header.vue";
import MockPagination from "../../mixins/Mock/MockPagination.js";
import Pagination from "../../components/Pagination.vue";

export default {
  name: "UnitList",
  mixins: [MeterConfigUnitList, MockPagination],
  components: { Filters, Table, Actions, Notification, Header, Pagination, Popup, Select },
  data() {
    return {
      filtersActive: {},
      rowsSelected: [],
    };
  },
  watch: {
    filtersActive: {
      handler(value) {
        if (value.status === null) {
          value.status = '';
        }
        
        this.filterContacts(value)
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.address-list {
  &__notification,
  &__title,
  &__filters,
  &__list {
    margin: 0px $space-md $space-lg;
  }
}
</style>

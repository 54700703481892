import httpProvider from 'Providers/http.js';

export default {
    getMeterConfigs(filters = {}) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/meter/meter-config?' + new URLSearchParams(filters).toString()).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getMeterConfig(meterConfigId) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/meter/meter-config/show/' + meterConfigId).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    addMeterConfig(data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/meter/meter-config/create', data, {headers: {
                'Content-Type': 'multipart/form-data'
            }}).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    addContacts(intercomId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/meter/meter-config/add-contacts/' + intercomId, data, {headers: {
                'Content-Type': 'multipart/form-data'
            }})
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateMeterConfig(meterConfigId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/meter/meter-config/update/' + meterConfigId, data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    removeMeterConfigs(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/meter/meter-config/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
}
import MeterConfig from "Pages/MeterConfig/Index.vue";
import AddMeterConfig from "Pages/AddMeterConfig/Index.vue";
import Units from "Pages/AddMeterConfig/Units.vue";
import Components from "Pages/Components.vue";
import Address from "Pages/Address/Index.vue";
import EditAddress from "Pages/Address/Edit.vue";
import Server from "Pages/Server/Index.vue";
import MobileAuth from "Pages/MobileAuth.vue";
import Auth from "Pages/Auth.vue";
import Oidc from "Pages/Oidc.vue";

import auth from "Middleware/auth.js";
import guest from "Middleware/guest.js";

export default [
  {
    path: "/oidc/callback",
    name: "Oidc",
    component: Oidc,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/",
    name: "Auth",
    component: Auth,
    meta: {
      // middleware: [guest]
    }
  },
  {
    path: "/auth/mobile",
    name: "MobileAuth",
    component: MobileAuth,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: "/meter-configs",
    name: "MeterConfigs",
    component: MeterConfig,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/meter-configs/add",
    name: "AddMeterConfig",
    component: AddMeterConfig,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/units",
    name: "Units",
    component: Units,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/components",
    name: "Components",
    component: Components,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/address/:meterConfigId",
    name: "Address",
    component: Address,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/editAddress/:meterConfigId",
    name: "EditAddress",
    component: EditAddress,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/server/:serverId",
    name: "Server",
    component: Server,
    meta: {
      middleware: [auth]
    }
  },
];

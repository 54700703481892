<template>
  <section class="address">
    <div class="address__header">
      <Header type="h4" spaced>
        <template v-slot:title>Настроить связку сервера с адресом</template>
        <template v-slot:addons v-if="removable">
          <span @click="$emit('remove')" class="address__header-remove">
            <icon-base icon-name="minus-outlined" label="Убрать адрес" />
          </span>
        </template>
      </Header>
    </div>
    <div class="address__form">
      <FormList v-model="formValue" :list="formList" flex-row />
    </div>
    <!-- <div class="address__file">
      <FormFile v-model="fileValue" label="Загрузить файл с абонентами" />
    </div> -->
  </section>
</template>

<script>
import FormList from "../../components/Form/List.vue";
// import FormFile from "../../components/Form/File.vue";
import Header from "../../components/Header.vue";
import IconBase from "../../components/Icons/IconBase.vue";

export default {
  name: "Address",
  components: { Header, FormList, IconBase },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    // file: {
    //   type: File,
    //   default: () => {},
    // },
    index: {
      type: Number,
      default: 1,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    formList: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formValue: {
      get() {
        return {
          ...this.form,
        };
      },
      set(value) {
        this.$emit("update:form", { ...value });
      },
    },
    // fileValue: {
    //   get() {
    //     return this.file;
    //   },
    //   set(value) {
    //     this.$emit("update:file", value);
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
.address {
  display: flex;
  flex-direction: column;
  gap: $space-xmd;
  margin: $space-lg $space-md;
  padding: $space-lg;
  border: 1px solid $add-intercom-addres-border-color;
  border-radius: $border-radius-lg;

  &__header {
    &-remove {
      cursor: pointer;
    }
  }
}
</style>

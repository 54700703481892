<template>
  <Multiselect
    v-model="inputValue"
    :options="optionsFormatted"
    :mode="mode"
    :placeholder="placeholder"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :hide-selected="hideSelected"
    :no-options-text="'Нет записей'"
    :multipleLabel="multipleLabel"
  >
    <template v-slot:nooptions v-if="$slots.nooptions">
      <slot name="nooptions" />
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "Select",
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      type: [Array, String],
      default: [],
    },
    options: {
      type: Array,
      default: [],
    },
    mode: {
      type: String,
      default: "single",
    },
    placeholder: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    multipleLabel: {
      type: Function,
      default() {
        'Выбрано'
      }
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    optionsFormatted() {
      return this.options.map((item) => ({
        value: item.id,
        ...item,
      }));
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>

::v-deep .multiselect {
  width: 100%;
  border: 1px solid $input-border-color;
  border-radius: $border-radius-xs;
  outline: none;
  // box-shadow: none;

  &-wrapper {
    padding: $space-xs + 3px $space-xs;
  }

  &-search {
    border-radius: $border-radius-xs;
  }

  &-dropdown {
    border-radius: $border-radius-xs;
    overflow-y: auto;
  }

  &-placeholder {
    color: $color-default;
  }
}
</style>

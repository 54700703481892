export default {
    state: {
        meterConfigs: [],
        tempMeterConfigIds: [],
        meterConfigsMeta: {},
    },
    getters: {
        meterConfigs: state => state.meterConfigs,
        tempMeterConfigIds: state => state.tempMeterConfigIds.length > 0 ? state.tempMeterConfigIds : JSON.parse(localStorage.getItem('tempMeterConfigIds')),
        meterConfigsMeta: state => state.meterConfigsMeta
    },
    mutations: {
        SET_METER_CONFIGS(state, payload) {
            state.meterConfigs = payload;
        },
        ADD_NEW_METER_CONFIG(state, payload) {
            state.meterConfigs.push(payload);
        },
        REMOVE_METER_CONFIGS(state, payload) {
            state.meterConfigs = state.meterConfigs.filter(meterConfig => !payload.includes(meterConfig.cuid));
        },
        SET_TEMP_METER_CONFIG_IDS(state, payload) {
            state.tempMeterConfigIds.push(payload);
            localStorage.setItem('tempMeterConfigIds', JSON.stringify(payload))
        },
        DELETE_TEMP_METER_CONFIG_IDS(state) {
            state.tempMeterConfigIds = [];
            localStorage.removeItem('tempMeterConfigIds');
        },
        SET_METER_CONFIGS_META(state, payload) {
            state.meterConfigsMeta = payload;
        }
    },
    actions: {
        setMeterConfigs({commit}, payload) {
            commit('SET_METER_CONFIGS', payload);
        },
        addNewMeterConfig({commit}, payload) {
            commit('ADD_NEW_METER_CONFIG', payload);
        },
        removeMeterConfigs({commit}, cuid) {
            commit('REMOVE_METER_CONFIGS', cuid);
        },
        setTempMeterConfigIds({commit}, payload) {
            commit('SET_TEMP_METER_CONFIG_IDS', payload);
        },
        deleteTempMeterConfigIds({commit}) {
            commit('DELETE_TEMP_METER_CONFIG_IDS');
        },
        setMeterConfigsMeta({commit}, payload) {
            commit('SET_METER_CONFIGS_META', payload);
        }
    }
}
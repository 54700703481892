import MeterConfigService from "Services/MeterConfigService.js";
import PropertyService from "Services/PropertyService.js";
import ServerService from "Services/ServerService.js";
import UnitService from "Services/UnitService.js";
import UnitTypes from "Providers/UnitTypes.js";

export default {
    data() {
        return {
            meterConfigService: MeterConfigService,
            propertyService: PropertyService,
            serverService: ServerService,
            unitService: UnitService,
            unitTypes: UnitTypes,
        }
    },
    methods: {
        getMeterConfigs(filters = {}) {
            this.meterConfigService.getMeterConfigs(filters).then(response => {
                if (response.meta) {
                    this.$store.dispatch('setMeterConfigsMeta', response.meta)
                }

                if (response.data) {
                  this.$store.dispatch('setMeterConfigs', response.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getProperties(filters = {}) {
            this.propertyService.getProperties(filters).then(response => {
                if (response.data) {
                  this.$store.dispatch('setProperties', response.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getPropertiesWithContacts(string = null) {
            this.propertyService.getPropertiesWithContacts(string).then(response => {
                if (response.data) {
                  this.$store.dispatch('setPropertiesWithContacts', response.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getServers(filters = {}) {
            this.serverService.getServers(filters).then(response => {
                if (response.meta) {
                    this.$store.dispatch('setServersMeta', response.meta)
                }

                if (response.data) {
                  this.$store.dispatch('setServers', response.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getContactsByOrganization(organizationId, propertyId = null, sipServerId = null) {
            this.unitService.getContactsByOrganization(organizationId, propertyId, sipServerId).then(response => {
                this.$store.dispatch('setUnits', response.data ?? [])
            }).catch((error) => {
                if (error.response && error.response.data) {
                    this.$store.dispatch("setNotification", { text: error.response.data.message ?? 'Error' });
                }
            })
        },
        getUnits(filters = {}) {
            return new Promise((resolve, reject) => {
                this.unitService.getUnits(filters).then(response => {
                    this.$store.dispatch('setUnitsMeta', response.meta)
                    this.$store.dispatch('setUnits', response.data ? response.data : [])

                    resolve(response);
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.$store.dispatch("setNotification", { text: error.response.data.message ?? 'Error' });
                    }

                    resolve(error)
                })
            });
        },
        getVerifiedColumnContent(id, label) {
            let content = {id: id};

            if (label) {
                content['label-true'] = label;
            } else {
                content['label-false'] = label;
            }

            return content;
        },
        getAccessRules(serverId) {
            return new Promise((resolve, reject) => {
                this.serverService.getAccessRules(serverId).then(response => {
                    this.$store.dispatch('setServerRules', {
                        serverId: serverId,
                        rules: response.data
                    })

                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    computed: {
        meterConfigList() {
            return this.$store.getters.meterConfigs;
        },
        meterConfigsMeta() {
            return this.$store.getters.meterConfigsMeta;
        },
        serverList() {
            return this.$store.getters.servers;
        },
        serversMeta() {
            return this.$store.getters.serversMeta;
        },
        propertyList() {
            return this.$store.getters.properties;
        },
        unitList() {
            return this.$store.getters.units;
        },
        unitsMeta() {
            return this.$store.getters.unitsMeta;
        },
        propertiesWithContactsList() {
            return this.$store.getters.propertiesWithContacts;
        },
        serverListOptions() {
            let serverListOptions = [];
      
            this.serverList.forEach((server) => {
              serverListOptions.push({
                id: server.id,
                label: server.host
              });
            });
      
            return serverListOptions;
        },
        propertyListOptions() {
            let propertyListOptions = [];
      
            this.propertyList.forEach((property) => {
              propertyListOptions.push({
                id: property.puid,
                label: property.address
              });
            });
      
            return propertyListOptions;
        },
        meterConfigPropertyListOptions() {
            let meterConfigPropertyList = [];

            this.meterConfigList.forEach(meterConfig => {
                if (meterConfigPropertyList.length > 0) {
                    meterConfigPropertyList.forEach(property => {
                        if (property.address != meterConfig.property.address) {
                            meterConfigPropertyList.push({
                                id: meterConfig.property.puid,
                                label: meterConfig.property.address
                            })
                        }
                    })
                } else {
                    meterConfigPropertyList.push({
                        id: meterConfig.property.puid,
                        label: meterConfig.property.address
                    })
                }
            })

            return meterConfigPropertyList
        },
        unitTypeListOptions() {
            let unitTypeListOptions = [];

            for (const [key, value] of Object.entries(this.unitTypes)) {
                unitTypeListOptions.push({
                    id: key,
                    label: value
                })
            }

            return unitTypeListOptions;
        },
        currentOrganizationId() {
            return this.$store.getters.currentOrganizationId;
        },
        serverRules() {
            return this.$store.getters.serverRules;
        }
    }
}
export default {
    state: {
        units: [],
        unitsMeta: {}
    },
    getters: {
        units: state => state.units,
        unitsMeta: state => state.unitsMeta,
    },
    mutations: {
        SET_UNITS(state, payload) {
            state.units = payload;
        },
        ADD_NEW_CONTACT(state, payload) {
            state.units.push(payload);
        },
        REMOVE_UNITS(state, payload) {
            state.units = state.units.filter(server => !payload.includes(server.id));
        },
        SET_UNITS_META(state, payload) {
            state.unitsMeta = payload;
        }
    },
    actions: {
        setUnits({commit}, payload) {
            commit('SET_UNITS', payload);
        },
        addNewContact({commit}, payload) {
            commit('ADD_NEW_CONTACT', payload);
        },
        removeUnit({commit}, suid) {
            commit('REMOVE_Units', suid);
        },
        setUnitsMeta({commit}, payload) {
            commit('SET_UNITS_META', payload);
        }
    }
}
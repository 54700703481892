<template>
    <div class="content pad-t-20 pad-b-20">
        {{ message }}
    </div>
</template>

<script>

import AuthService from '../services/AuthService.js';
import OrganizationService from '../services/OrganizationService.js';
import ErrorMessages from '../providers/ErrorMessages.js';

export default {
    name: 'Oidc',
    data() {
        return {
            message: null,
            authService: AuthService,
            organizationService: OrganizationService,
            errorMessages: ErrorMessages
        }
    },
    mounted() {
        this.message = this.errorMessages.initializing;
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('code') && urlParams.has('state')) {
            let code = urlParams.get('code');
            let state = urlParams.get('state');

            this.authService.getAccessTokenWithCodeAndState(code, state).then(response => {
                if (response.data) {
                    if (localStorage.getItem('isMobileAuth')) {
                        this.message = JSON.stringify(response.data);
                        localStorage.removeItem('isMobileAuth');
                    } else {
                        this.$store.dispatch('setAccessToken', response.data.access_token ?? null);
                        this.$store.dispatch('setRefreshToken', response.data.refresh_token ?? null);
                        this.setCurrentOrganizationData();
                    }
                } else {
                    this.message = this.errorMessages.authError;
                }
            }).catch(error => {
                this.message = this.errorMessages.authError;
                console.log(error);
            });
        } else {
            this.message = this.errorMessages.missingRequiredAuthParams;
        }
    },
    methods: {
        setCurrentOrganizationData() {
            this.organizationService.getOrganizationByGuid(this.$store.getters.condoOrganizationId).then(response => {
                console.log(response.data)
                if (response.data && response.data.ouid) {
                    this.$store.dispatch('setCurrentOrganizationId', response.data.ouid);
                    this.$router.push({name: 'Intercom'});
                } else {
                    this.message = this.errorMessages.organizationNotFound;
                }
            }).catch(error => {
                this.message = this.errorMessages.organizationNotFound;
                console.log(error);
            })
        }
    }
}

</script>


<style scoped lang="scss">



</style>
<template>
  <section
    :class="[
      'list',
      {
        'list_flex-row': flexRow,
      },
    ]"
  >
    <div
      v-for="item in list"
      :key="item.id"
      class="list__item"
      :style="`flex:${item.width || 1}`"
    >
      <div class="list__item-label">
        {{ item.label }}
        <span v-if="item.required" class="list__item-label-required">*</span>
      </div>
      <component :is="item.type" v-model="values[item.id]" v-bind="item.attr">
        <template v-slot:nooptions v-if="item.nooptions">
          <icon-base
            :icon-name="item.nooptions.icon"
            :label="item.nooptions.label"
            class="list__item-nooptions"
            @click="item.nooptions.handler"
          />
        </template>
      </component>
    </div>
  </section>
</template>

<script>
import Input from "../../components/Form/Input.vue";
import Select from "../../components/Form/Select.vue";
import IconBase from "../../components//Icons/IconBase.vue";

export default {
  name: "List",
  components: { Input, Select, IconBase },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: [],
    },
    flexRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: { ...this.modelValue },
    };
  },
  watch: {
    values: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: $space-lg;

  &_flex-row {
    flex-direction: row;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: $space-xxs;

    &-label {
      color: $form-list-label-color;
      font-size: $font-size-md;
      line-height: $line-height-md;

      &-required {
        color: $color-red;
      }
    }

    &-nooptions {
      padding: $space-xs;
    }
  }
}
</style>

import { createStore } from "vuex";

import auth from "Store/modules/auth.js";
import meterConfig from "Store/modules/meterConfig.js";
import server from "Store/modules/server.js";
import property from "Store/modules/property.js";
import unit from "Store/modules/unit.js";
import notifications from "Store/modules/notifications.js";
import common from "Store/modules/common.js";

export default createStore({
  modules: {
    auth,
    meterConfig,
    server,
    property,
    unit,
    notifications,
    common,
  },
});

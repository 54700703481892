import httpProvider from 'Providers/http.js';

export default {
    getContactsByOrganization(organizationId, propertyId = null, sipServerId = null) {
        const params = { organizationId: organizationId };
        
        if (propertyId) {
            params.propertyId = propertyId;
        }

        if (sipServerId) {
            params.sipServerId = sipServerId;
        }
        
        return new Promise((resolve, reject) => {
            httpProvider.get('/meter/user/contacts?' + new URLSearchParams(params).toString())
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getUnits(filters = {}) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/meter/meter-config/units?' + new URLSearchParams(filters).toString())
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateContact(data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/meter/user/contact/update/' + data.id, data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    removeUnits(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/meter/meter-config/units/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    
}
<template>
  <Popup :title="title">
    <FormList v-model="value" :list="list" />
  </Popup>
</template>

<script>
import types from "../../config/types.js";
import Popup from "../../components/Popup.vue";
import FormList from "../../components/Form/List.vue";
import ServiceData from "../../mixins/ServiceData.js"

export default {
  name: "Server",
  components: { Popup, FormList },
  mixins: [ServiceData],
  data() {
    return {
      serverTypes: []
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: types.serverModes.add,
    },
  },
  mounted() {},
  computed: {
    title() {
      if (this.mode === types.serverModes.add) return "Добавить сервер";
      if (this.mode === types.serverModes.edit) return "Редактировать сервер";
      return "Сервер";
    },
    // serverTypeLabels() {
    //   let labels = [];

    //   this.serverTypes.forEach(type => {
    //     labels.push({
    //       id: type.stuid,
    //       label: type.name
    //     });
    //   })

    //   return labels;
    // },
    value: {
      get() {
        return { ...this.modelValue };
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    list() {
      return [
        {
          id: "host",
          label: "Имя сервера (хост)",
          type: types.components.input,
          required: true,
          width: 12,
          attr: {
            placeholder: "myserver.com",
          },
        },
        // {
        //   id: "port",
        //   label: "Порт",
        //   type: types.components.input,
        //   width: 12,
        //   attr: {
        //     placeholder: "Порт",
        //   },
        // },
        {
          id: "login",
          label: "Логин",
          type: types.components.input,
          required: true,
          width: 12,
          attr: {
            placeholder: "Логин",
          },
        },
        {
          id: "password",
          label: "Пароль",
          type: types.components.input,
          required: true,
          width: 12,
          attr: {
            placeholder: "Пароль",
          },
        },
        // {
        //   id: "fromdomain",
        //   label: "Fromdomain",
        //   type: types.components.input,
        //   width: 12,
        //   attr: {
        //     placeholder: "Fromdomain",
        //   },
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>

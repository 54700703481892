import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import MockPagination from "./MockPagination.js";
import ServiceData from "Mixins/ServiceData.js";
import MockAccessRules from "./MockAccessRules.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination, MockAccessRules],
  data() {
    return {
      searchTimeOut: null,
      actionValues: {3: false},
      filtersData: {},
      currentServer: {},
    };
  },
  computed: {
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        // {
        //   id: "propertyIds",
        //   type: types.components.select,
        //   width: 5,
        //   attr: {
        //     placeholder: "Все адреса",
        //     options: this.propertyListOptions,
        //   },
        // },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
        },
        {
          id: "flat",
          label: "Квартира",
          onclick: () => {
            this.filtersData.order = 'unit_name';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_name',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "flat_type",
          label: "Тип помещения",
          onclick: () => {
            this.filtersData.order = 'unit_type';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'unit_type',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "account_number",
          label: "Лицевой счет",
          onclick: () => {
            this.filtersData.order = 'account_number';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredContacts();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'account_number'
        },
        // {
        //   id: "section",
        //   label: "Подъезд",
        //   onclick: () => {
        //     this.filtersData.order = 'section';
        //     this.toggleColumnOrderType(this.filtersData.order);
        //     this.getFilteredContacts();
        //   },
        //   clickable: true,
        //   orderType: this.filtersData.order_type,
        //   selected: this.filtersData.order === 'section'
        // },
      ];
    },
    tableRows() {
      const data = [];

      this.unitList.forEach((unit) => {
        data.push({
          id: unit.uid,
          columns: [
            {
              id: "address",
              content: {
                id: unit.id,
                label: unit.meter_config.property.address,
              },
            },
            {
              id: "flat",
              content: {
                id: unit.id,
                label: unit.name,
              },
            },
            {
              id: "flat_type",
              content: {
                id: unit.id,
                label: this.unitTypes[unit.type] ?? '-',
              },
            },
            {
              id: "account_number",
              content: {
                id: unit.id,
                label: unit.account_number,
              },
            },
            // {
            //   id: "section",
            //   content: {
            //     id: unit.id,
            //     label: unit.section ?? '-',
            //   },
            // },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        default: [
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "primary",
              label: "Сохранить",
              disabled: !this.actionValues['3'],
              handler: () => this.onSaveContacts(),
            },
          },
          {
            id: 3,
            type: types.actions.checkbox,
            attr: {
              label:
                "Подтверждаю, что данные верны, готов опубликовать их в мобильном приложении жителя",
              handler: () => this.actionValues['3'] = !this.actionValues['3'],
            },
          },
        ],

        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Изменить верификацию",
              icon: "check",
              handler: () => this.verifySelectedContacts(),
            },
          },
        ],
      };
    },
  },
  methods: {
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    onSaveContacts() {
      this.$store.dispatch("deleteTempMeterConfigIds");
      this.$store.dispatch("setNotification", { text: "Телеметрии добавлены" });
      this.$router.push({ name: "MeterConfigs" });
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.verifyContact(row);
    },
    verifySelectedContacts() {
      this.$store.dispatch('setIsLoading', true)
      let data = { contacts: [] };
      this.rowsSelected.forEach((value) => {
        data.contacts.push({ id: value });
      });

      this.unitService
        .verifyContacts(data)
        .then((response) => {
          this.rowsSelected = [];
          this.getFilteredContacts();
          this.$store.dispatch('setIsLoading', false)
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch('setIsLoading', false)
        });
    },
    verifyContact(contactId) {
      this.$store.dispatch('setIsLoading', true)
      this.unitService
        .verifyContacts({ contacts: [{ id: contactId }] })
        .then((response) => {
          this.getFilteredContacts();
          this.$store.dispatch('setIsLoading', false)
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch('setIsLoading', false)
        });
    },
    filterContacts(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut);
      }

      this.searchTimeOut = setTimeout(() => {
        filters.organizationId = this.currentOrganizationId;
        filters.meterConfigIds = this.$store.getters.tempMeterConfigIds;
        this.filtersData = filters;

        this.getFilteredContacts();
      }, 2000);
    },
    setUnitsPagination() {
      this.initPagination(
        this.unitsMeta.current_page ?? 1,
        this.unitsMeta.total ?? 1,
        this.unitsMeta.per_page ?? 1
      );
    },
    getFilteredContacts() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.meterConfigIds = this.$store.getters.tempMeterConfigIds;
      this.getUnits(this.filtersData).then(response => {
        this.setUnitsPagination();
      });
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredContacts();
    },
    getContactsWithRules() {
      this.$store.dispatch('setIsLoading', true)
      this.getUnits({
        organizationId: this.currentOrganizationId,
        meterConfigIds: this.$store.getters.tempMeterConfigIds,
      }).then(response => {
        this.setUnitsPagination();
        this.currentServer = this.unitList[0]?.meter_config?.server;
        
        this.$store.dispatch('setIsLoading', false)
      }).catch(error => {
        this.$store.dispatch('setIsLoading', false)
      });
    }
  },
  async mounted() {
    if (this.serverList.length === 0) {
      this.getServers({
        organizationId: this.currentOrganizationId,
      });
    }

    this.getProperties({
      organizationId: this.currentOrganizationId,
      meterConfigIds: this.$store.getters.tempMeterConfigIds,
    });

    this.getContactsWithRules()
  },
};

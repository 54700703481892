<template>
  <div
    :class="[
      `button button_${type} button_${colored}`,
      {
        button_disabled: disabled,
      },
    ]"
    @click="handler"
  >
    <div class="button__content">
      <icon-base
        v-if="icon"
        :icon-name="icon"
        v-bind="widthHeight"
        class="button__content-icon"
      />
      <div class="button__content-label">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "../../components/Icons/IconBase.vue";

export default {
  name: "Button",

  components: { IconBase },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    colored: {
      type: String,
      default: "not-colored",
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Button",
    },
    icon: {
      type: String,
      default: "",
    },
    handler: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    widthHeight() {
      return this.icon === "cross"
        ? {
            width: 24,
            height: 24,
          }
        : {
            width: 16,
            height: 16,
          };
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  $root: &;

  border-radius: $border-radius-md;
  background: $button-color;
  padding: 1px;
  cursor: pointer;
  display: inline-block;

  &__content {
    display: flex;
    gap: $space-xxs;
    align-items: center;
    padding: $space-xs $space-lg;
    border-radius: $border-radius-md - 1px;
    background: $color-white;

    &-label {
      font-weight: $font-weight-lg;
    }
  }

  &_disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.5;
  }

  &_primary {
    #{$root}__content {
      background: $button-color;

      &-icon,
      &-label {
        color: $color-white;
      }
    }

    &:hover {
      background: $gradient;

      #{$root}__content {
        background: unset;
      }
    }
  }

  &_secondary {
    &:hover {
      background: $gradient;

      #{$root}__content-icon {
        color: $color-light-green;
      }

      #{$root}__content-label {
        background: $gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &_danger {
    color: $color-red;
    background: $color-red;
  }
}
</style>

<template>
  <section class="address">
    <ServerPopup
      v-if="serverPopup.opened"
      v-model="serverPopup.modelValue"
      :mode="serverPopup.mode"
      @close="onServerPopupClose"
      @approve="onServerPopupApprove"
    />

    <Header class="address__header">
      <template v-slot:title>Телеметрия</template>
    </Header>
    <Header class="address__header" type="h3" spaced>
      <template v-slot:title>{{ address.address }}</template>
      <template v-slot:addons>
        <icon-base icon-name="server" :label="address.ip" />
        <Chips :chips="address.status ? [address.chips[0]] : [address.chips[1]]" />
      </template>
    </Header>
    <BackButton router-page-name="MeterConfigs" class="address__back">
      Назад
    </BackButton>
    <Header class="address__subheader" type="h4">
      <template v-slot:title>
        Абоненты
        <template v-if="address.count">({{ address.count }})</template>
      </template>
    </Header>
    <div class="address__filters">
      <Filters v-model="filtersActive" :filters="filters" />
    </div>
    <div class="address__list">
      <Table
        v-model="rowsSelected"
        :columns="tableColumns"
        :rows="tableRows"
        :selectable="false"
        @switcherClick="onSwitcherClick"
        @rowClick="onRowClick"
      />
      <Pagination v-model="currentPage" v-bind="pagination" @switchPage="onSwitchPage" />
    </div>
    <div class="address__actions">
      <Actions
        :actions="rowsSelected.length ? actions.selected : actions.default"
      />
    </div>
  </section>
</template>

<script>
import Popup from "../../components/Popup.vue";
import Header from "../../components/Header.vue";
import Filters from "../../components/Filters.vue";
import Table from "../../components/Table.vue";
import Actions from "../../components/Actions.vue";
import MeterConfigAddress from "../../mixins/Mock/MeterConfigAddress.js";
import IconBase from "../../components/Icons/IconBase.vue";
import Chips from "../../components/Chips.vue";
// import FormFile from "../../components/Form/File.vue";
import FormList from "../../components/Form/List.vue";
import MockPagination from "../../mixins/Mock/MockPagination.js";
import Pagination from "../../components/Pagination.vue";
import BackButton from "../../components/BackButton.vue";
import Select from "../../components/Form/Select.vue";
import ServerPopup from "../Popup/Server.vue";

export default {
  name: "AddressList",
  mixins: [MeterConfigAddress, MockPagination],
  components: {
    Popup,
    Header,
    ServerPopup,
    Filters,
    Table,
    Actions,
    IconBase,
    Chips,
    // FormFile,
    FormList,
    Pagination,
    BackButton,
    Select
  },
  data() {
    return {
      filtersActive: {},
      rowsSelected: [],
    };
  },
  watch: {
    filtersActive: {
      handler(value) {
        if (value.status === null) {
          value.status = '';
        }

        this.filterUnits(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  width: 100%;

  &__header,
  &__filters,
  &__back,
  &__list {
    margin: 0px $space-md $space-lg;
  }

  &__subheader {
    margin: $space-xl $space-md $space-lg;
  }
}
</style>
